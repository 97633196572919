import React from "react"
// import {graphql, useStaticQuery} from "gatsby"
// import Img from "gatsby-image"
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Svg from '../../assets/svg/grow.svg'
const useStyles = makeStyles((theme) => ({
        root: {
            width: '100%',
            // maxWidth: 1200,
            display: 'flex',
            // marginTop: '2rem',
            // marginBottom: '4rem',
            // flexGrow: 1,
            [theme.breakpoints.down("xs")]: {
                marginBottom: "1rem",
                marginTop: "1rem",
                // maxHeight: 200,
            },
        },
        slogan: {
            maxWidth: 500,
            color: "primary",
            fontSize: "6rem",
            fontWeight: 900,
            paddingLeft: "3rem",
            lineHeight: 1,
            position: "relative",
            bottom: "1rem",
            marginTop: "4rem",
            marginBottom: "2rem",
            [theme.breakpoints.down("sm")]: {
                maxWidth: 300,
                fontSize: "4rem",
                fontWeight: 900,
                paddingLeft: "1rem",
            },
            [theme.breakpoints.down("xs")]: {
               maxWidth: 150,
                fontSize: "2rem",
                fontWeight: 900,
                paddingLeft: "1rem",
                // top: "2rem"
            },
        },
        image: {
            maxWidth: 400,
            // paddingRight: 50,
            [theme.breakpoints.down("sm")]: {
                maxWidth: 300,
            },
            [theme.breakpoints.down("xs")]: {
                maxWidth: 200,
                // maxHeight: 200,
            },
        },
    img: {
        // maxWidth: 400,
        // // paddingRight: 50,
        // [theme.breakpoints.down("sm")]: {
        //     maxWidth: 300,
        // },
        [theme.breakpoints.down("xs")]: {
            // maxWidth: 200,
            maxHeight: 200,
        },
    }
    }))
;

function Hero() {
    const classes = useStyles()

    return (
        <Grid container
              direction="row"
              justify="space-around"
              alignItems="center"
              className={classes.root}>
            <Grid
                item
                // xs={4}
                // md={6}
            >
                <Box>
                    <Typography variant="h3" className={classes.slogan}>
                        We help to grow your business
                    </Typography>
                </Box>

            </Grid>
            <Grid
                container
                item
                // xs={8}
                // md={6}
                // justify="flex-end"
                className={classes.image}
            >
                <Svg className={classes.img}/>
            </Grid>
        </Grid>
    )

}

export default Hero

