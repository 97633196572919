import React from 'react';
import Layout from '../components/layout/Layout'
import Hero from '../components/front/Hero'
import About from '../components/front/About'
import Services from '../components/front/Services'
import Contact from '../components/front/Contact'


export default function Index() {
    return (
        <Layout>
            <Hero/>
            <About/>
            <Services/>
            <Contact/>
        </Layout>
    );
}
