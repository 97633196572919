import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Svg from '../../assets/svg/about.svg'

const useStyles = makeStyles({
    root: {
        backgroundColor: "#fafafa",
        marginBottom: "5rem",
        padding: "3rem"
    },
    img: {
        width: "80%",
        margin: "auto"
    },
    desc: {},
    title: {
        marginBottom: "2rem"
    },
    text: {
        lineHeight: "2"
    }

})

function About() {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} sm={6}>
                    <Svg className={classes.img}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="h4" className={classes.title}>
                        About Polish Link
                    </Typography>
                    <Typography variant="body1" className={classes.text}>
                        Since 2011 we provide our clients with the best business advice from point of view of software technology market. With solid
                        knowledge from business area combined with expertise from modern software landscape, we can pay attention to aspects
                        unseen by others.
                    </Typography>
                    <Typography variant="body1" className={classes.text}>
                        We are not "cheap", and we are not recommend "cheap" solutions to our customers only from the price point of view. However, we
                        are affordable and highly competitive cost-wise comparing to many available on market solutions, and we aim to spread customer
                        satisfaction over much longer period than just after purchase.
                    </Typography>

                </Grid>
            </Grid>

        </div>
    )
}

export default About