import React, {useState} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import axios from 'axios'
import {navigate} from 'gatsby';


const useStyles = makeStyles((theme) => ({
    root: {
        padding: "0.5rem",
        backgroundColor: "#00cc00",
        marginTop: "3rem",
        marginBottom: "3rem",
        borderRadius: "10px"
    },
    title: {
      margin: "1rem 0px 1rem 2rem"
    },
    row: {
        margin: "0.8rem 1rem 0.4rem 1rem"
    },
    cell: {
        margin: 1,
        backgroundColor: "#ffffff",
        borderRadius: 5
        // padding: "1rem"
    },
    form: {}
}))


function Contact() {
    const classes = useStyles()
    const [data, setData] = useState({})
    const [valid, setValid] = useState({
        nameOK: true,
        nameValidated: false,
        nameError: false,
        emailOK: true,
        emailValidated: false,
        emailError: false,
        messageOK: true,
        messageValidated: false,
        messageError: false
    })
    const [notRobot, setNotRobot] = useState(false)
    const endpoint = '.netlify/functions/contact'


    function handleRobot(e) {

        setNotRobot(!notRobot)
    }

    function handleError(msg) {
        console.log("Error: ", msg)
    }

    function handleSuccess() {
        setData({name: '', email: '', message: ''})
        navigate('thank-you')
    }

    function handleSend(e) {
        e.preventDefault()

        axios.post(endpoint, JSON.stringify(data)).then(response => {
            if (response.status !== 200) {
                handleError()
            } else {
                handleSuccess()

            }
        })
    }


    function validateName(value) {
        if (value.length > 4) {
            setValid({...valid, nameOK: true, nameError: false, nameValidated: true})
        } else {
            setValid({...valid, nameError: true, nameOK: false, nameValidated: true})
        }
    }

    function validateEmail(value) {
        if (value.match(/^\S+@\S+[.][0-9a-z]+$/)) {
            setValid({...valid, emailOK: true, emailError: false, emailValidated: true})
        } else {
            setValid({...valid, emailError: true, emailValidated: true})
        }
    }

    function validateMessage(value) {
        if (value.length > 14) {
            setValid({...valid, messageOK: true, messageError: false, messageValidated: true})
        } else {
            setValid({...valid, messageError: true, messageValidated: true})
        }
    }

    function handleMessageChange(value) {
        setData({...data, message: value})
        validateMessage(value)
    }


    return (
        <div className={classes.root}>
            <Box  className={classes.title}>
                <Typography variant="h2" color="primary"> Contact Form</Typography>
            </Box>

            <form
                name="Contact form"
                method="POST"
                className={classes.form}
                // data-netlify="true"
                // data-netlify-recaptcha="true"

            >
                <input type="hidden" name="form-name" value="Contact Form"/>

                <Grid container>
                    <Grid container item spacing={2} className={classes.row}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                name="name"
                                id="name"
                                label="Name"
                                variant="outlined"
                                fullWidth className={classes.cell}
                                onChange={e => setData({...data, name: e.target.value})}
                                onBlur={e => validateName(e.target.value)}
                                error={valid.nameError}
                                helperText={valid.nameError ? 'Name must consists of at least 4 characters' : null}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                name="email"
                                id="email"
                                label="Email address"
                                variant="outlined"
                                fullWidth className={classes.cell}
                                onChange={e => setData({...data, email: e.target.value})}
                                onBlur={e => validateEmail(e.target.value)}
                                error={valid.emailError}
                                helperText={valid.emailError ? 'Provide valid email address.' : null}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item spacing={2} className={classes.row}>
                        <Grid item xs={12}>
                            <TextField
                                name="message"
                                id="message"
                                label="Message"
                                multiline
                                rows={4}
                                defaultValue=""
                                variant="outlined"
                                fullWidth
                                className={classes.cell}
                                // onChange={e => setData({...data, message: e.target.value})}
                                onChange={e => handleMessageChange(e.target.value)}
                                // onBlur={e => validateMessage(e.target.value)}
                                error={valid.messageError}
                                helperText={valid.messageError ? 'Please provide longer message (min 15 characters)' : null}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item spacing={2} className={classes.row}>
                        <Grid item md={8}>
                            <FormControlLabel
                                control={<Checkbox color="primary" checked={notRobot} onChange={e => handleRobot(e)}
                                                   name="robot"/>}
                                label="I am not a robot"
                            />
                            {/*<div data-netlify-recaptcha="true"></div>*/}
                        </Grid>
                        <Grid item md={4}>
                            <Button
                                // type="submit"
                                variant="contained"
                                color="primary"
                                onClick={handleSend}
                                disabled={
                                    !(
                                        valid.nameValidated
                                        && !valid.nameError
                                        && valid.emailValidated
                                        && !valid.emailError
                                        && valid.messageValidated
                                        && !valid.messageError
                                        && notRobot
                                    )
                                }
                            >
                                Send enquiry
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </form>


        </div>
    )
}

export default Contact