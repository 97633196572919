import React from 'react';
import {graphql, useStaticQuery} from "gatsby"
import ServiceSingle from './ServiceSingle'
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    title: {
        margin: "1rem 0px 1rem 2rem"
    },
}))
function Services() {
    const classes = useStyles();
    const data = useStaticQuery(graphql`
        query Services {
            allFile(filter: {extension: {eq: "svg"}}) {
                nodes {
                    publicURL
                    name
                }
            }
            allMarkdownRemark(sort: {fields: frontmatter___order}, filter: {frontmatter: {type: {eq: "offer"}}}) {
                nodes {
                    frontmatter {
                        text
                        title
                        svg_name
                        order
                        type
                    }
                    html
                }
            }
        }

    `)
    const {allMarkdownRemark, allFile} = data // data.markdownRemark holds your post data
    const documents = allMarkdownRemark.nodes
    const pictures = allFile.nodes

    let services = documents.map(doc => {
        doc.html && console.log(doc.html)
        let pic = pictures.find(p => p.name === doc.frontmatter.svg_name)
        return doc = {html:doc.html, ...doc.frontmatter, ...pic}

    })

    return (
        <div>
            <Box  className={classes.title}>
                <Typography variant="h2" color="primary"> Our Services</Typography>
            </Box>
            {services.map(serv => (
                <ServiceSingle
                    title={serv.title}
                    text={serv.text}
                    imgURL={serv.publicURL}
                    order={serv.order}
                    html={serv.html}

                />
            ))}
        </div>
    )
}

export default Services