import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles({
    root: {
        backgroundColor: "#fafafa",
        // margin: "1rem",
        marginBottom: "2rem",
        padding: "1rem"
    },
    box: {
        width: "80%",
        // margin: "auto"
    },
    img: {
        margin: "auto",
        width: "100%",
        height: "100%",
        objectFit: 'cover'
    },
    desc: {},
    title: {
        marginBottom: "2rem"
    },
    text: {
        lineHeight: "2"
    }

})

function ServiceSingle({title, text, imgURL, order, html}) {
    const theme = useTheme();
    const isBigger = useMediaQuery(theme.breakpoints.up('sm'));
        const classes = useStyles()
    if (isBigger) {
        if (order % 2 === 0) {
            return (
                <div className={classes.root}>
                    <Grid container  alignItems="center">
                        <Grid item xs={12} sm={6} align="center">
                            <Box className={classes.box} >
                                <img className={classes.img} src={imgURL} alt="Polish Link services" />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="h4" className={classes.title}>
                                {title}
                            </Typography>
                            <Typography variant="body1" className={classes.text}>
                                {text}
                            </Typography>
                            <Typography variant="subtitle1" className={classes.text} dangerouslySetInnerHTML={{ __html: html }} />
                        </Grid>
                    </Grid>

                </div>
            )
        } else  {
            return (
                <div className={classes.root}>
                    <Grid container  alignItems="center">
                        <Grid item xs={12} sm={6}>
                            <Typography variant="h4" className={classes.title}>
                                {title}
                            </Typography>
                            <Typography variant="body1" className={classes.text}>
                                {text}
                            </Typography>
                            <Typography variant="subtitle1" className={classes.text} dangerouslySetInnerHTML={{ __html: html }} />
                        </Grid>
                        <Grid item xs={12} sm={6} align="center">
                            <Box className={classes.box}>
                                <img className={classes.img} src={imgURL} alt="Polish Link serices" />
                            </Box>
                        </Grid>

                    </Grid>

                </div>
            )

        }
    } else {
        return (
            <div className={classes.root}>
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={6}>
                        <div className={classes.img}>
                            <img src={imgURL} alt="Polish Link serices" />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h4" className={classes.title}>
                            {title}
                        </Typography>
                        <Typography variant="body1" className={classes.text}>
                            {text}
                        </Typography>
                        <Typography variant="subtitle1" className={classes.text} dangerouslySetInnerHTML={{ __html: html }} />
                    </Grid>
                </Grid>

            </div>
        )

    }


}

export default ServiceSingle